import "./reportSectionHeaderToggleContainer.scss";

type ReportSectionHeaderToggleContainerProps = {
    title: React.ReactNode;
    children: React.ReactNode;
};

export function ReportSectionHeaderToggleContainer(props: ReportSectionHeaderToggleContainerProps) {
    return (
        <div className="report-section-header-toggle-container">
            <span className="report-section-header-toggle-container__title">{props.title}</span>

            {props.children}
        </div>
    );
}
