import clsx from "clsx";
import { ReactNode } from "react";
import "./reportSectionRoot.scss";

type ReportSectionRootProps = {
    children: ReactNode;

    className?: string;
};

export function ReportSectionRoot(props: ReportSectionRootProps) {
    return <div className={clsx("report-section-root", props.className)}>{props.children}</div>;
}
