import { faChartSimple, faTable } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "@/components/icons/FaIcon/FaIcon";
import { ReportSectionHeaderToggleButton } from "@/components/ReportsPage/ReportSection/ReportSectionHeader/ReportSectionHeaderToggleButton/ReportSectionHeaderToggleButton";
import { ReportSectionHeaderToggleContainer } from "@/components/ReportsPage/ReportSection/ReportSectionHeader/ReportSectionHeaderToggleContainer/ReportSectionHeaderToggleContainer";

type ReportSectionHeaderViewToggleProps = {
    view: View;
    onViewChange: (view: View) => void;
};

export function ReportSectionHeaderViewToggle(props: ReportSectionHeaderViewToggleProps) {
    return (
        <ReportSectionHeaderToggleContainer title="Ansicht">
            <ReportSectionHeaderToggleButton
                isActive={props.view === "chart"}
                onActivate={() => props.onViewChange("chart")}>
                <FaIcon icon={faChartSimple} spacing="right" />
                Chart
            </ReportSectionHeaderToggleButton>
            <ReportSectionHeaderToggleButton
                isActive={props.view === "table"}
                onActivate={() => props.onViewChange("table")}>
                <FaIcon icon={faTable} spacing="right" />
                Tabelle
            </ReportSectionHeaderToggleButton>
        </ReportSectionHeaderToggleContainer>
    );
}

type View = "chart" | "table";
