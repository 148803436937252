import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton } from "@ionic/react";
import clsx from "clsx";
import { ReactNode, useRef, useState } from "react";
import * as FaPopover from "@/components/FaPopover";
import { ReportSectionHeaderConfigurationApplyButton } from "@/components/ReportsPage/ReportSection/ReportSectionHeader/ReportSectionHeaderConfigurationPopover/ReportSectionHeaderConfigurationApplyButton/ReportSectionHeaderConfigurationApplyButton";
import { ReportSectionHeaderConfigurationResetButton } from "./ReportSectionHeaderConfigurationResetButton/ReportSectionHeaderConfigurationResetButton";
import "./reportSectionHeaderConfigurationPopover.scss";

type ReportSectionHeaderConfigurationPopoverProps = {
    children: ReactNode;

    classNames?: ReportSectionHeaderConfigurationPopoverClassNames;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;

    /**
     * If set to `false` indicates that the configuration is not the default
     * @default true
     */
    isDefaultConfiguration?: boolean;

    onDiscard?: () => void;
    onApplyConfiguration?: () => void;
    onResetConfiguration?: () => void;
};

export function ReportSectionHeaderConfigurationPopover(props: ReportSectionHeaderConfigurationPopoverProps) {
    const { isDefaultConfiguration = true, ...restProps } = props;

    const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
    const open = props.open || uncontrolledOpen;
    const contentRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const handleResetClick = () => {
        restProps.onResetConfiguration?.();
        setUncontrolledOpen(false);
    };

    const handleApplyClick = () => {
        restProps.onApplyConfiguration?.();
        setUncontrolledOpen(false);
    };

    return (
        <FaPopover.Root
            open={open}
            onOpenChange={open => {
                props.onOpenChange?.(open);
                setUncontrolledOpen(open);
            }}
            onOverlayClick={props.onDiscard}
            ref={overlayRef}>
            <FaPopover.Trigger>
                <IonButton
                    color="medium"
                    className={clsx(
                        "report-section-header-configuration-popover__trigger",
                        restProps.classNames?.trigger
                    )}>
                    {!isDefaultConfiguration && (
                        <span className="report-section-header-configuration-popover__changes" />
                    )}
                    <FontAwesomeIcon
                        className="report-section-header-configuration-popover__trigger-icon"
                        icon={faSliders}
                    />
                    Konfiguration
                </IonButton>
            </FaPopover.Trigger>

            <FaPopover.Content
                ref={contentRef}
                sideOffset={8}
                collisionPadding={16}
                onOpenAutoFocus={event => {
                    event.preventDefault();
                }}
                className={clsx("report-section-header-configuration-popover__content", restProps.classNames?.content)}>
                {restProps.children}

                <>
                    <div className="report-section-header-configuration-popover__divider" />
                    <div className="report-section-header-configuration-popover__footer">
                        <ReportSectionHeaderConfigurationResetButton onClick={handleResetClick} />
                        <ReportSectionHeaderConfigurationApplyButton onClick={handleApplyClick} />
                    </div>
                </>
            </FaPopover.Content>
        </FaPopover.Root>
    );
}

type ReportSectionHeaderConfigurationPopoverClassNames = {
    trigger?: string;
    content?: string;
};
