import { ReactNode } from "react";

type ReportSectionContentProps = {
    children: ReactNode;

    className?: string;
};

export function ReportSectionContent(props: ReportSectionContentProps) {
    return <div className={props.className}>{props.children}</div>;
}
