import clsx from "clsx";
import "./reportSectionHeaderConfigurationResetButton.scss";

type ReportSectionHeaderConfigurationResetButtonProps = React.ComponentPropsWithoutRef<"button">;

export function ReportSectionHeaderConfigurationResetButton(props: ReportSectionHeaderConfigurationResetButtonProps) {
    return (
        <button {...props} className={clsx("report-section-header-configuration-reset-button", props.className)}>
            {props.children || "Zurücksetzen"}
        </button>
    );
}
