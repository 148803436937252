import { IonButton } from "@ionic/react";
import "./reportSectionHeaderToggleButton.scss";

type ReportSectionHeaderToggleButtonProps = {
    isActive: boolean;
    onActivate: () => void;

    children: React.ReactNode;
};

export function ReportSectionHeaderToggleButton(props: ReportSectionHeaderToggleButtonProps) {
    return (
        <IonButton
            className="report-section-header-toggle-button"
            color={props.isActive ? "primary" : "light"}
            onClick={props.onActivate}>
            {props.children}
        </IonButton>
    );
}
