import clsx from "clsx";
import "./reportSectionHeaderConfigurationApplyButton.scss";

type ReportSectionHeaderConfigurationApplyButtonProps = React.ComponentPropsWithoutRef<"button">;

export function ReportSectionHeaderConfigurationApplyButton(props: ReportSectionHeaderConfigurationApplyButtonProps) {
    return (
        <button {...props} className={clsx("report-section-header-configuration-apply-button", props.className)}>
            {props.children || "Übernehmen"}
        </button>
    );
}
