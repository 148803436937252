import { ReactNode } from "react";
import "./reportSectionHeaderConfiguration.scss";

type ReportSectionHeaderConfigurationProps = {
    children: ReactNode;
};

export function ReportSectionHeaderConfiguration(props: ReportSectionHeaderConfigurationProps) {
    return <div className="report-section-header-configuration">{props.children}</div>;
}
