import { ReactNode } from "react";
import "./reportSectionHeader.scss";

type ReportSectionHeaderProps = {
    children: ReactNode;
};

export function ReportSectionHeader(props: ReportSectionHeaderProps) {
    return <div className="report-section-header">{props.children}</div>;
}
